import React from 'react';
import s from "./PageHeader-Logo.module.scss";
import Image from "next/image";
import Link from "next/link";

const PageHeaderLogo = () => {
    return (
        (<Link href={"/"} className={s.logo}>
        <>
            <Image src={"/assets/img/logo.png"} fill style={{objectFit:"contain", objectPosition:"left"}}
                   alt="logo"/>
        </>
        </Link>)
    );
};

export default PageHeaderLogo;

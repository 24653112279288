import {DetailedHTMLProps, FC, HTMLAttributes, useEffect} from 'react';
import styles from './ClickOutside.module.scss';


const ClickOutside: FC<TClickOutside> = (
    {
        reference,
        onClickOutside,
        children,
        ...restProps
    }): JSX.Element => {

    useEffect(() => {
        const clickOutSide = (e: MouseEvent) => {
            if (!!reference && !reference.contains(e.target as Element)) {
                onClickOutside()
            }
        }

        document.addEventListener('mousedown', clickOutSide)

        return () => {
            document.removeEventListener('mousedown', clickOutSide)
        }
    }, [reference, onClickOutside])

    return (
        <div {...restProps} className={styles.container}>
            {children}
        </div>
    );
};


export default ClickOutside;


type TClickOutside = {
    reference: Element
    onClickOutside: () => void
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

import { useState } from 'react';
import s from './PageHeaderMobile.module.scss';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import PageHeaderLogo from '../Logo/PageHeader-Logo';
import { Icon } from '../../../../components/Icon/Icon';
import LangToggle from '../../../../components/LangToggle/LangToggle';
import PageHeaderRootMenu from '../RootMenu/PageHeader-RootMenu';
import PageHeaderProfile from '../Profile/PageHeader-Profile';

const PageHeaderMobile = ({ user, setShowLoginPopup, rootMenu }) => {
    const { t } = useTranslation('common');
    const router = useRouter();

    const [show, setShow] = useState<boolean>(false);

    const handleClose = () => {
        setShow(false);
        // props.toggleRootMenu(false);
    };

    const handleAddButton = () => {
        user?.id ? router.push('/new-advert') : setShowLoginPopup(true);
    };

    const handleFavoritesClick = () => {
        user?.id ? router.push('/profile/favorites') : setShowLoginPopup(true);
    };

    const handleLoginClick = () => {
        handleClose();
        setShowLoginPopup(true);
    };

    const handleExpand = () => {
        setShow(true);
    };

    return (
        <>
            <div className={`${s.wrapper}`}>
                <PageHeaderLogo />
                <button
                    className={s.favoritesButton}
                    type="button"
                    onClick={handleFavoritesClick}
                >
                    <Icon
                        name={'like'}
                        size={20}
                        stroke={'#D8352B'}
                        color={'#FFFFFF'}
                    />
                </button>
                <button
                    className={s.addButton}
                    type="button"
                    onClick={handleAddButton}
                >
                    <Icon name={'add_circle'} size={24} />
                </button>
                <div className={s.langToggle}>
                    <LangToggle hideFlag={true} />
                </div>
                <button
                    className={s.burger}
                    type="button"
                    onClick={handleExpand}
                >
                    <Icon name={'burger'} size={22} color={'black'} />
                </button>
            </div>

            <div className={`${s.nav} ${show ? s.nav_show : ''}`}>
                <button
                    className={s.nav__close}
                    onClick={handleClose}
                    type="button"
                >
                    <Icon name={'cross'} size={16} stroke={'white'} />
                </button>

                <nav className={s.nav__list}>
                    <li className={s.nav__menuMobile}>
                        <div className={s.nav__title}>{t('Category')}</div>
                    </li>
                    <li className={s.nav__menuMobile} onClick={handleClose}>
                        {rootMenu?.length > 0 && (
                            <PageHeaderRootMenu
                                rootMenu={rootMenu}
                                toggle={() => {}}
                            />
                        )}
                    </li>

                    {!!user?.id ? (
                        <PageHeaderProfile setShow={handleClose} />
                    ) : (
                        <li className={s.nav__item}>
                            <button
                                className={`${s.nav__button}`}
                                onClick={handleLoginClick}
                                type="button"
                            >
                                {t('Login')}
                            </button>
                        </li>
                    )}
                </nav>
            </div>
        </>
    );
};

export default PageHeaderMobile;

import s from './CustomButton.module.scss';
import { CSSProperties, SyntheticEvent } from 'react';

export type ButtonProps = {
    title: string;
    disabled?: boolean;
    clickAction: () => void;
    styles?: CSSProperties;
    variant?: 'primary' | 'secondary' | 'critical';
    type?: 'submit' | 'button';
};



const CustomButton = (props: ButtonProps) => {
    const handleClick  = (e: SyntheticEvent) => {
        e.stopPropagation();
        props.clickAction();
    }

    return (
        <button
            onClick={handleClick}
            className={`${s.button} ${s[props.variant] || ""}`}
            disabled={props.disabled}
            style={props.styles || {}}
            type={props.type || 'button'}
        >
            {props.title}
        </button>
    );
};

export default CustomButton;

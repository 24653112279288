import Head from 'next/head';
import { Wrapper } from '@googlemaps/react-wrapper';
import PageHeader from './PageHeader/PageHeader';
import PageFooter from './PageFooter/PageFooter';
import React, { FC, useEffect } from 'react';
import { useRouter } from 'next/router';
import { TLayout } from './TLayout';
import getConfig from 'next/config';
import ContactPopupProvider from '../context/ContactPopupContext';
import { YandexMetrika } from './Trackers/YandexMetrikaTag';
import { GoogleTagManager } from './Trackers/GoogleTagManager';
import SearchBar from '../components/SearchBar/SearchBar';
// import Script from 'next/script';

const PublicLayout: FC<TLayout> = ({ children }) => {
    const router = useRouter();

    const { publicRuntimeConfig } = getConfig();
    useEffect(() => {
        const handleRouteChange = (url) => {
            if (url.startsWith('/admin')) {
                window.location.href = url;
                throw 'Abort route to admin area.';
            }
        };

        router.events.on('routeChangeStart', handleRouteChange);
    }, []);
    return (
        <>
            <Head>
                <title>Odin 24</title>
                <meta
                    name="description"
                    content="ODIN24 is an electronic catalogue of advertisements of goods, services, vacancies and other offers that users can offer and search on ODIN24.se"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                />
                <link rel="shortcut icon" href="/assets/favicon.ico" />
                <GoogleTagManager />
                <YandexMetrika />
                {/* <script
                    async
                    src={
                        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=' +
                        process.env.NEXT_PUBLIC_SENSE_SLOT
                    }
                    crossOrigin="anonymous"
                ></script> */}

                <meta name="robots" content="all" />
                <meta name="yandex-verification" content="6bcbaa5a5c4a7fe9" />
                <meta
                    name="google-site-verification"
                    content="9bzuy0pNNoiWF1mhN5o9gagzCv2r6Q8AfD8xCe9gDmA"
                />
            </Head>
            {/* <Script
                strategy='afterInteractive'
                async
                src={
                    'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=' +
                    process.env.NEXT_PUBLIC_SENSE_CLIENT_ID
                }
                crossOrigin="anonymous"
            /> */}
            <PageHeader />
            <main>
                <Wrapper
                    apiKey={publicRuntimeConfig.GOOGLE_API_KEY ?? ''}
                    libraries={['places']}
                    language={'sv'}
                >
                    <>
                        {!router.asPath.startsWith('/profile') &&
                            !router.asPath.startsWith('/new-advert') && (
                                <div className="container">
                                    <SearchBar />
                                </div>
                            )}
                        {children}
                    </>
                </Wrapper>
            </main>
            <ContactPopupProvider>
                <PageFooter />
            </ContactPopupProvider>
        </>
    );
};

export default PublicLayout;

import React, { useState } from 'react';
import s from './PageHeader-RootMenu.module.scss';
import { PageHeaderRootMenuProps } from '../../PageHeaderTypes';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import { useRouter } from 'next/router';
import Link from 'next/link';
import ClickOutside from '../../../../components/ClickOutside/ClickOutside';

const PageHeaderRootMenu = (props: PageHeaderRootMenuProps) => {
    const [ref, setRef] = useState(null);

    const { locale: lang } = useRouter();

    const current = useAppSelector((state) => state.category.current);
    const advert = useAppSelector((state) => state.advert.data);
    const root = current.url
        ? current.url.split('/')[0]
        : advert.category
        ? advert.category.url.split('/')[0]
        : '';

    const menuNodes = props.rootMenu?.map((category) => {
        return (
            <li
                key={category.id}
                className={
                    root === category.url
                        ? `${s.menu__item} ${s.menu__item_active}`
                        : `${s.menu__item}`
                }
                onClick={() => {
                    props.toggle();
                }}
            >
                <Link href={'/list/' + category.url}>
                    {category.lang[lang]}
                </Link>
            </li>
        );
    });

    return (
        <div className={s.container} ref={setRef}>
            <ClickOutside reference={ref} onClickOutside={props.toggle}>
                <nav className={`${s.menu}`}>{menuNodes}</nav>
            </ClickOutside>
        </div>
    );
};

export default PageHeaderRootMenu;

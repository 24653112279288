import React, { useEffect, useRef } from 'react';
import { useAuth } from '../../../../context/AuthContext';
import s from './PageHeader-Profile.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { isAdmin } from '../../../../utils/isAdmin';

const PageHeaderProfile = ({ setShow }: TPageHeaderProfile) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const auth = useAuth();
    const user = auth.user;

    const { t } = useTranslation('common');

    const hideProfile = () => {
        setShow(false);
    };

    const clickOutSide = (e: MouseEvent) => {
        if (
            containerRef.current &&
            !containerRef.current.contains(e.target as Element)
        ) {
            hideProfile();
        }

        return true;
    };

    useEffect(() => {
        document.addEventListener('mousedown', { handleEvent: clickOutSide });

        return () => {
            document.removeEventListener('mousedown', {
                handleEvent: clickOutSide,
            });
        };
    }, []);

    return (
        <>
            {user && (
                <div className={s.container} ref={containerRef}>
                    <Link className={s.user} href={'/profile'} onClick={()=>{hideProfile()}}>
                        <div className={s.info}>
                            <div className={s.name}>
                                {user.firstName} {user.lastName || ''}
                            </div>
                            <div className={s.email}>{user.email}</div>
                        </div>
                        <div className={s.avatar}>
                            {user.avatar ? (
                                <Image
                                    src={user.avatar}
                                    style={{ objectFit: 'cover' }}
                                    fill
                                    alt={'user avatar'}
                                />
                            ) : (
                                <div className={s.avatar__placeholder}>
                                    {user.firstName[0]}
                                    {user.lastName && user.lastName[0]}
                                </div>
                            )}
                        </div>
                    </Link>
                    <nav className={s.menu}>
                        <li className={s.menu__item} onClick={hideProfile}>
                            <Link href={'/profile'}>{t('Profile')}</Link>
                        </li>
                        <li className={s.menu__item} onClick={hideProfile}>
                            <Link href={'/profile/messages'} passHref>
                                <>
                                    {t('Messages')}
                                    {user?.unread_messages ? (
                                        <span
                                            className={s.unread_messages}
                                        ></span>
                                    ) : (
                                        ''
                                    )}
                                </>
                            </Link>
                        </li>
                        <li className={s.menu__item} onClick={hideProfile}>
                            <Link href={'/profile/announcements'} passHref>
                                {t('Announcements')}
                            </Link>
                        </li>
                        <li className={s.menu__item} onClick={hideProfile}>
                            <Link href={'/profile/services'} passHref>
                                {t('Additional services')}
                            </Link>
                        </li>
                        <li className={s.menu__item} onClick={hideProfile}>
                            <Link href={'/profile/rating'} passHref>
                                {t('My rating')}
                            </Link>
                        </li>
                        <li className={s.menu__item} onClick={hideProfile}>
                            <Link href={'/profile/settings'} passHref>
                                {t('Settings')}
                            </Link>
                        </li>
                        {isAdmin(user) && (
                            <li className={s.menu__item} onClick={hideProfile}>
                                <Link href={'/admin'} locale={null}>
                                    {t('Admin Panel')}
                                </Link>
                            </li>
                        )}
                        <li className={s.menu__item}>
                            <button
                                type="button"
                                onClick={() => {
                                    setShow(false);
                                    auth.logout();
                                }}
                            >
                                {t('Sign out')}
                            </button>
                        </li>
                    </nav>
                </div>
            )}
        </>
    );
};

type TPageHeaderProfile = {
    setShow: (value: boolean) => void;
};

export default PageHeaderProfile;

import s from './LangToggle.module.scss';
import { useState } from 'react';
import { Icon } from '../Icon/Icon';
import { LangToggleProps } from './LangToggleTypes';
import { useRouter } from 'next/router';

const LangToggle = (props: LangToggleProps) => {
    const router = useRouter();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleSwitchLang = (switchedLang: string) => {
        if (switchedLang !== router.locale) {
            const maxAge = 60 * 60 * 24 * 30; // 30 Days
            document.cookie =
                'NEXT_LOCALE=' + switchedLang + '; path=/; max-age=' + maxAge;
        }

        router.push(router.asPath, router.asPath, { locale: switchedLang });
    };

    const langs = router.locales;

    const langsNodes = langs.map((item, index) => {
        if (item !== router.locale) {
            return (
                <div
                    key={index}
                    className={`${s.langs__item}`}
                    onClick={() => handleSwitchLang(item)}
                >
                    <span className={s.flag}>
                        {' '}
                        <Icon name={item} />
                    </span>
                    <span className={s.country}>{item}</span>
                </div>
            );
        }
    });

    const selectedClass = s.langs__item + ' ' + s.langs__item_selected;
    return (
        <div className={s.wrapper} onClick={() => setIsOpen(!isOpen)}>
            <div className={`${s.selected} ${selectedClass}`}>
                {!props.hideFlag ? (
                    <span className={s.flag}>
                        <Icon name={router.locale} />
                    </span>
                ) : (
                    ''
                )}

                <span className={s.country}>{router.locale}</span>
            </div>
            {isOpen ? <div className={s.langs}>{langsNodes}</div> : ''}
            <div className={s.arrow}>
                <Icon
                    name={'arrow_down'}
                    size={10}
                    color={'#276EAC'}
                    stroke={'#276EAC'}
                />
            </div>
        </div>
    );
};

export default LangToggle;
